import React from 'react'
import { RotatingLines } from "react-loader-spinner";
import { ScaleLoader } from "react-spinners";
function FormLoader({isLoading}) {

  return (
    <div>
        {/* <div
        style={{
          position:"absolute",
          display:"flex",
          alignItems:"center",
          justifyContent:"center",
          zIndex:"10",
          opacity: "1",
          marginTop:"20%",
          marginLeft:"38%",
        }}
      >
        <RotatingLines
          strokeColor="red"
          strokeWidth="5"
          animationDuration="0.75"
          width="150"
          visible={isLoading}
        /> */}


<ScaleLoader
        color={"#004280"}
        loading={isLoading}
        style={{
          display: "block",
          textAlign: "center",
          margin: "20%",
          borderColor: "#b76b29",
        }}
        // css={override}
        size={300}
      />
    
      {/* </div> */}
    </div>
  )
}

export default FormLoader