import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ic_logout from "../assets/images/ic_logout.svg";
// import wellbeinglogo from "../assets/images/wellbeinglogo.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { sidebar } = useContext(AppContext);
  const navigate = useNavigate();
  const currentRoute = useLocation().pathname;
  const logout = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/");
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}  >
      Logout
    </Tooltip>
  );
  return (
    <main className="cx-header">
      <div
        className={
          sidebar ? "cx-header-wrapper" : "cx-active cx-header-wrapper"
        }
      >
        <header>
          <div></div>
          <div className="right">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 100, hide: 100 }}
              overlay={renderTooltip}
            >
           
                <img src={ic_logout} className="ic-logout" alt=""  onClick={logout}/>
              
            </OverlayTrigger>
          </div>
        </header>
      </div>
    </main>
  );
};

export default Header;
