import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./../context/AppContext";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
// import ApexCharts from 'apexcharts'
import Chart from "react-apexcharts";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import ic_install from "../assets/images/ic_install.svg";
import ic_newReturn from "../assets/images/ic_newReturn.svg";
import ic_activeUser from "../assets/images/ic_activeUser.svg";
import ic_location from "../assets/images/ic_location.svg";
import ic_device from "../assets/images/ic_device.svg";
import ic_registered from "../assets/images/ic_registered.svg";
import Modal from "react-bootstrap/Modal";
import ic_time from "../assets/images/ic_time.svg";
import Aqaaid_active from "../assets/images/Aqaaid_active.svg";
import Seerat_active from "../assets/images/Seerat_active.svg";
import Noraani_Qaaidah_active from "../assets/images/Noraani_Qaaidah_active.svg";
import Dua_Sunnat_active from "../assets/images/Dua_Sunnat_active.svg";
import Hifz_e_Surah_active from "../assets/images/Hifz_e_Surah_active.svg";
import Namaaz_active from "../assets/images/Namaaz_active.svg";
import Masaail_active from "../assets/images/Masaail_active.svg";
import Umrah_Hajj_active from "../assets/images/Umrah_Hajj_active.svg";
import { ToastMsg } from "./TostMsg";
import { simpleGetCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";

const Dashboard = () => {
  const { sidebar } = useContext(AppContext);
  const navigate = useNavigate();
const [dashboardCount,setDashboardCount]=useState([])
  const [locationChart, setlocationChart] = useState({
    options: {
      chart: {
        id: "basic-bar"
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      xaxis: {
        categories: ['India', 'Bangladesh', 'Dubai', 'Sri Lanka', 'Italy', 'France', 'Japan',
          'United States', 'China', 'Germany'
        ],
      }
    },
    series: [
      {
        name: "series-1",
        data: [900, 100, 200, 300, 400, 500, 600, 520, 800, 900, 50]
      }
    ]
  
  });

  const [iOSChart, setiOSChart] = useState({
    options: {
      chart: {
        id: "basic-bar"
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      xaxis: {
        categories: ['Andriod', 'iOS'
        ],
      }
    },
    series: [
      {
        name: "series-1",
        data: [800, 50]
      }
    ]
  
  });
  const [AndroidChart, setAndroidChart] = useState({
    options: {
      chart: {
        id: "basic-bar"
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      xaxis: {
        categories: ['Andriod 11', 'Andriod 10', 'Andriod 9', 'Andriod 8.1.0', 'Andriod 12', 'Andriod 7.0', 'Andriod 6.1',
        'Andriod 5.1.1', 'iOS 15.4', 'iOS 15.2'
        ],
      }
    },
    series: [
      {
        name: "series-1",
        data: [320, 290, 260, 230, 195, 150, 140, 110, 70, 60, 30]
      }
    ]
  
  });

  const [location, setlocation] = useState(false);
  const handlelocationClose = () => setlocation(false);
  const handlelocationShow = () => setlocation(true);

  const [devices, setDevice] = useState(false);
  const handledevicesClose = () => setDevice(false);
  const handledevicesShow = () => setDevice(true);


  useEffect(() => {
    $(document).ready(function () {
      $("#table_id").DataTable();
    });
  }, []);
  useEffect(()=>{
    dashboard()
  },[])
  const dashboard = () => {
   
    // setIsLoading(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.DASHBOARD,

    //   JSON.stringify({
    //     ...body,
    //   })
     )
      .then((res) => {
        // setLoader(false);
// console.log(res);
        if (res.json && res.json?.success === true) {
          // ToastMsg("success", res.json.message);
  setDashboardCount(res.json.data)
          // handleDeleteClose();
          // SabaqListApi();
          // setIsDeleteList(true);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const naviGationRoute=(id)=>{
    // console.log(id,"fscxvbxvb")
    if(id==2){
      navigate("/NoraaniQaaidah")
    }
   else if (id==3){
      navigate("/HifzESurah")
    }  
    else   if(id==4){
      navigate("/DuaSunnat")
    }    else  if(id==5){
      navigate("/Aqaaid")
    }    else  if(id==6){
      navigate("/Masaail")
    }  else    if(id==7){
      navigate("/UmrahHajj")
    }   else   if(id==8){
      navigate("/Namaaz")
    }   else   if(id==9){
      navigate("/Seerat")
    }
    else   if(id==10){
      navigate("/Ramzan")
    }
  }

  
  return (

    <main
      id="cx-main"
      className={sidebar ? "dashboard-main" : "cx-active dashboard-main"}
    >
      <div className="dashboard-wrapper">
        <div className="heading-common">
          <label htmlFor="">DASHBOARD</label>
        </div>
        <div className="row mt-3 main-section">
          <div className="col-md-6 left-section">
            <div className="row">
              <h5>App Content Count</h5>
              {dashboardCount &&dashboardCount.length>0 && dashboardCount.map((ele,index)=>{
                return(
                  <div className="col-md-6" key={"index"+index} >
                  <div className="mainCards" onClick={()=>naviGationRoute(ele.category_id)}>
                    <div className="ineerCard">
                      <div className="innerIcon">
                        <img src={ele.categoryImagePath} alt="" />
                      </div>
                      <div className="innerText">
                        <div className="col headingText">{ele.category_name}</div>
                        <div className="col count">{ele.contentCount}</div>
                      </div>
                    </div>
                  </div>
                </div>
                )
              })}
           
            </div>
          </div>
          <div className="right-section col-md-6">
            <div className="row">
              <h5>Analytics</h5>
              <div className="col-md-6">
                <Link className="mainCards" to="#">
                  <div className="ineerCard">
                    <div className="innerIcon">
                      <img src={ic_install} alt="" />
                    </div>
                    <div className="innerText">
                      <div className="col headingText">Total Install</div>
                      <div className="col count">13</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-6">
                <Link className="mainCards" to="#">
                  <div className="ineerCard">
                    <div className="innerIcon">
                      <img src={ic_newReturn} alt="" />
                    </div>
                    <div className="innerText">
                      <div className="col headingText">New/Returning Users</div>
                      <div className="col count">
                        <span>1478</span>/<span>1458</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-6">
                <Link className="mainCards" to="#" onClick={handlelocationShow}>
                  <div className="ineerCard">
                    <div className="innerIcon">
                      <img src={ic_location} alt="" />
                    </div>
                    <div className="innerText">
                      <div className="col headingText">Location</div>
                      <div className="col count">34</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-6">
                <Link className="mainCards" to="#" onClick={handledevicesShow}>
                  <div className="ineerCard">
                    <div className="innerIcon">
                      <img src={ic_device} alt="" />
                    </div>
                    <div className="innerText">
                      <div className="col headingText">Device/Version</div>
                      <div className="col count">20</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-6">
                <Link className="mainCards" to="#">
                  <div className="ineerCard">
                    <div className="innerIcon">
                      <img src={ic_time} alt="" />
                    </div>
                    <div className="innerText">
                      <div className="col headingText">Screen Time</div>
                      <div className="col count">1358</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-6">
                <Link className="mainCards" to="#">
                  <div className="ineerCard">
                    <div className="innerIcon">
                      <img src={ic_registered} alt="" />
                    </div>
                    <div className="innerText">
                      <div className="col headingText">Registered</div>
                      <div className="col count">78</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-6">
                <Link className="mainCards" to="#">
                  <div className="ineerCard mb-0">
                    <div className="innerIcon">
                      <img src={ic_activeUser} alt="" />
                    </div>
                    <div className="innerText">
                      <div className="col headingText">Active User</div>
                      <div className="col count">14</div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <Modal
              show={location}
              onHide={handlelocationClose}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Location</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5 className="mb-3">Cumulative total of countries : 10</h5>
                <Chart
                  options={locationChart?.options}
                  series={locationChart?.series}

                  type="bar"
                  width="100%"
                />
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>


            <Modal
              show={devices}
              onHide={handledevicesClose}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Devices</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5 className="mb-3">Cumulative total of OS : 18</h5>

                {/* iOS Chart */}
                <Chart
                  options={iOSChart?.options}
                  series={iOSChart?.series}

                  type="bar"
                  width="100%"
                />
                {/* Android chart */}
                <Chart
                  options={AndroidChart?.options}
                  series={AndroidChart?.series}

                  type="bar"
                  width="100%"
                />
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>

          </div>
        </div>
      </div>
    </main>
  );
};

export default Dashboard;
