export const BASE_URL = "https://deeniyat-selflearning.app.redbytes.in:3006/";
const BASE_URL_download = "https://deeniyat-selflearning.app.redbytes.in";
 const NOORANIQAIDAH= "nooraniQaidah/" 
const ApiConfig = {
  BASE_URL_download,
  BASE_URL_FOR_IMAGES: "https://deeniyat-selflearning.app.redbytes.in:3006/",
  BASE_URL: BASE_URL,
  LOGIN: BASE_URL + "login",
  DASHBOARD: BASE_URL + "dashboard/appContentCount",
  SUBAQADD: BASE_URL +NOORANIQAIDAH+"addSabaq",
  SUBAQUPDATENORANI: BASE_URL + NOORANIQAIDAH+"updateSabaq",
  SUBAQADDHIFZ: BASE_URL +"hifzeSurah/addSurah",
  SUBAQUPDATHIFZ: BASE_URL + "hifzeSurah/updateSurah",
  SUBAQADDALL: BASE_URL +"addSabaq",
  SUBAQUPDATE: BASE_URL + "updateSabaq/",
  SUBAQLIST: BASE_URL + "sabaqList",
  EDIT: BASE_URL +NOORANIQAIDAH+ "subSabaqList",
  EDITALL: BASE_URL + "subSabaqList",
  EDITHIFZ: BASE_URL + "hifzeSurah/ayahList",
  DELETEAPI: BASE_URL +"deleteSabaq",
 

  
};
export default ApiConfig;
