import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import ic_sidemenu_toggle from "../assets/images/ic_sidemenu_toggle.svg";
import { useMediaQuery } from "react-responsive";
import Deeniyat_logo from "../assets/images/logo_big.png";
import deeniyatAppicon from "../assets/images/logo_small.png";

import ic_dashboard from "../assets/images/ic_dashboard.svg";

import Aqaaid from "../assets/images/Aqaaid.svg";
import Aqaaid_active from "../assets/images/Aqaaid_active.svg";

import Seerat from "../assets/images/Seerat.svg";
import Ramzan from "../assets/images/Ramzan.svg";
import Ramzan_Active from "../assets/images/Ramzan_Active.svg";
import Seerat_active from "../assets/images/Seerat_active.svg";
import Noraani_Qaaidah from "../assets/images/Noraani_Qaaidah.svg";
import Noraani_Qaaidah_active from "../assets/images/Noraani_Qaaidah_active.svg";
import ic_setting from "../assets/images/ic_setting.svg";
import ic_setting_active from "../assets/images/ic_setting_active.svg";

import Dua_Sunnat from "../assets/images/Dua_Sunnat.svg";
import Dua_Sunnat_active from "../assets/images/Dua_Sunnat_active.svg";
import Hifz_e_Surah from "../assets/images/Hifz_e_Surah.svg";
import Hifz_e_Surah_active from "../assets/images/Hifz_e_Surah_active.svg";
import Namaaz from "../assets/images/Namaaz.svg";
import Namaaz_active from "../assets/images/Namaaz_active.svg";
import Masaail from "../assets/images/Masaail.svg";
import Masaail_active from "../assets/images/Masaail_active.svg";
import Umrah_Hajj from "../assets/images/Umrah_Hajj.svg";
import Umrah_Hajj_active from "../assets/images/Umrah_Hajj_active.svg";

import { AppContext } from "../context/AppContext";

const Sidebar = () => {
  const navigate = useNavigate();
  const currentRoute = useLocation().pathname;
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  useEffect(() => {
    isMobile ? setSidebar(false) : setSidebar(true);
    return () => {};
  }, [isMobile]);

  const { sidebar, setSidebar, Dark } = useContext(AppContext);
  const handleSidebar = () => {
    setSidebar(!sidebar);
  };

  const [setting, setsetting] = useState(false);
  return (
    <main className="cx-sidebar">
      <div
        className={
          sidebar ? "cx-sidebar-wrapper " : "cx-sidebar-wrapper sidebarActive"
        }
      >
        <aside>
          <div className="logo-main">
            {sidebar ? (
              <div className="big">
                <img src={Deeniyat_logo} alt="" />
              </div>
            ) : (
              <div className="small">
                <img src={deeniyatAppicon} alt="" />
              </div>
            )}
          </div>

          <div className="left-arrow"></div>

          <div className="menus-main">
            <div className="menus-items" id="arrow-double">
              <div
                className={sidebar ? "text-end " : "doubleArrowActive"}
                onClick={handleSidebar}
                id="arrow-inner"
              >
                <img src={ic_sidemenu_toggle} alt="" />
              </div>
            </div>

            <div
              className={
                currentRoute === "/Dashboard"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Dashboard" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/dashboard" className="me-2">
                      {currentRoute === "/Dashboard" ? (
                        <img src={ic_dashboard} alt="" />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#707070"
                            d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
                          />
                        </svg>
                      )}
                    </Link>
                    Dashboard
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Dashboard" ? (
                      <img src={ic_dashboard} alt="" />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#16173b"
                          d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/NoraaniQaaidah"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/NoraaniQaaidah" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/NoraaniQaaidah" className="me-2">
                      {currentRoute === "/NoraaniQaaidah" ? (
                        <img src={Noraani_Qaaidah_active} alt="" />
                      ) : (
                        <img src={Noraani_Qaaidah} alt="" />
                      )}
                    </Link>
                    Noraani Qaaidah
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/NoraaniQaaidah" ? (
                      <img src={Noraani_Qaaidah_active} alt="" />
                    ) : (
                      <img src={Noraani_Qaaidah} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/HifzESurah"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/HifzESurah" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/HifzESurah" className="me-2">
                      {currentRoute === "/HifzESurah" ? (
                        <img src={Hifz_e_Surah_active} alt="" />
                      ) : (
                        <img src={Hifz_e_Surah} alt="" />
                      )}
                    </Link>
                    Hifz-e-Surah
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/HifzESurah" ? (
                      <img src={Hifz_e_Surah_active} alt="" />
                    ) : (
                      <img src={Hifz_e_Surah} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/DuaSunnat"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/DuaSunnat" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/DuaSunnat" className="me-2">
                      {currentRoute === "/DuaSunnat" ? (
                        <img src={Dua_Sunnat_active} alt="" />
                      ) : (
                        <img src={Dua_Sunnat} alt="" />
                      )}
                    </Link>
                    Dua Sunnat
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/DuaSunnat" ? (
                      <img src={Dua_Sunnat_active} alt="" />
                    ) : (
                      <img src={Dua_Sunnat} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/Aqaaid"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Aqaaid" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Aqaaid" className="me-2">
                      {currentRoute === "/Aqaaid" ? (
                        <img src={Aqaaid_active} alt="" />
                      ) : (
                        <img src={Aqaaid} alt="" />
                      )}
                    </Link>
                    Aqaaid
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Aqaaid" ? (
                      <img src={Dua_Sunnat_active} alt="" />
                    ) : (
                      <img src={Dua_Sunnat} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/Masaail"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Masaail" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Masaail" className="me-2">
                      {currentRoute === "/Masaail" ? (
                        <img src={Masaail_active} alt="" />
                      ) : (
                        <img src={Masaail} alt="" />
                      )}
                    </Link>
                    Masaail
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Masaail" ? (
                      <img src={Masaail_active} alt="" />
                    ) : (
                      <img src={Masaail} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/UmrahHajj"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/UmrahHajj" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/UmrahHajj" className="me-2">
                      {currentRoute === "/UmrahHajj" ? (
                        <img src={Umrah_Hajj_active} alt="" />
                      ) : (
                        <img src={Umrah_Hajj} alt="" />
                      )}
                    </Link>
                    Umrah & Hajj
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/UmrahHajj" ? (
                      <img src={Umrah_Hajj_active} alt="" />
                    ) : (
                      <img src={Umrah_Hajj} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/Namaaz"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Namaaz" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Namaaz" className="me-2">
                      {currentRoute === "/Namaaz" ? (
                        <img src={Namaaz_active} alt="" />
                      ) : (
                        <img src={Namaaz} alt="" />
                      )}
                    </Link>
                    Namaaz
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Namaaz" ? (
                      <img src={Namaaz_active} alt="" />
                    ) : (
                      <img src={Namaaz} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/Seerat"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Seerat" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Seerat" className="me-2">
                      {currentRoute === "/Seerat" ? (
                        <img src={Seerat_active} alt="" />
                      ) : (
                        <img src={Seerat} alt="" />
                      )}
                    </Link>
                    Seerat
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Seerat" ? (
                      <img src={Seerat_active} alt="" />
                    ) : (
                      <img src={Seerat} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>


            <div
              className={
                currentRoute === "/Ramzan"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Ramzan" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/Ramzan" className="me-2">
                      {currentRoute === "/Ramzan" ? (
                        <img src={Ramzan_Active} alt="" />
                      ) : (
                        <img src={Ramzan} alt="" />
                      )}
                    </Link>
            Ramzan
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Ramzan" ? (
                      <img src={Ramzan_Active} alt="" />
                    ) : (
                      <img src={Ramzan} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>
            <div
              className={
                currentRoute === "/GoogleAnalytics" ||
                currentRoute === "/PushNotification"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link
                to="#"
                className="icon-menu-name"
                onClick={() => setsetting(!setting)}
              >
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="#" className="me-2">
                      {currentRoute === "/GoogleAnalytics" ||
                      currentRoute === "/PushNotification" ? (
                        <img src={ic_setting_active} alt="" />
                      ) : (
                        <img src={ic_setting} alt="" />
                      )}
                    </Link>
                    Setting
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/GoogleAnalytics" ||
                    currentRoute === "/PushNotification" ? (
                      <img src={ic_setting_active} alt="" />
                    ) : (
                      <img src={ic_setting} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>
            {(sidebar && setting) == true ?  (
              <div className="sub-menu-main">
                <Link to="/GoogleAnalytics" className="sub-menu-item">
                  <div className={ currentRoute === "/GoogleAnalytics" ? "sub-menu active-sub-menu" : "sub-menu"}>Google Analytics</div>
                </Link>

                <Link to="/PushNotification" className="sub-menu-item">
                  <div className={currentRoute === "/PushNotification" ? "sub-menu active-sub-menu" : "sub-menu"}>Push Notification</div>
                </Link>
              </div>
            ):""}
          </div>
        </aside>
      </div>
    </main>
  );
};

export default Sidebar;
